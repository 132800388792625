import { formatInTimeZone } from 'date-fns-tz'
import type { GroupedBookingSlots } from '~/@types/graphql-police'
import { PARIS_TIME_ZONE } from '~/helpers/date'
import type { DateValue } from '@heroui/react'
import { CalendarDate, parseDate, today } from '@internationalized/date'

export function getCalendarOptions(slots: GroupedBookingSlots[]) {
  const [firstDaySlots, lastDaySlots] = [slots.at(0), slots.at(-1)]

  const minDate = firstDaySlots
    ? parseDate(firstDaySlots.date)
    : today(PARIS_TIME_ZONE)
  const maxDate = lastDaySlots
    ? parseDate(lastDaySlots.date)
    : today(PARIS_TIME_ZONE)

  const calendarDaysFromSlots = slots.map((daySlots) => {
    const firstSlot = daySlots.bookingSlots[0]!

    // We need to use formatInTimeZone to get the date in ISO format at the Paris time zone
    const dateISO = formatInTimeZone(
      new Date(firstSlot.startDate),
      PARIS_TIME_ZONE,
      'yyyy-MM-dd'
    )

    return parseDate(dateISO)
  })

  const matchIsDateUnavailable = (calendarDate: DateValue) => {
    const isBetweenMinAndMax = calendarDate > minDate && calendarDate < maxDate

    return (
      isBetweenMinAndMax &&
      calendarDaysFromSlots.every((calendarDayFromSlot) => {
        return calendarDayFromSlot.compare(calendarDate) !== 0
      })
    )
  }

  return {
    minDate,
    maxDate,
    calendarDaysFromSlots,
    matchIsDateUnavailable
  }
}

export const getSlotsFromDay = (
  slots: GroupedBookingSlots[],
  day: CalendarDate
) => {
  const selectedSlotsDay = slots.find((daySlots) => {
    return day.compare(parseDate(daySlots.date)) === 0
  })

  return selectedSlotsDay?.bookingSlots ?? []
}
